
/* eslint-disable no-constant-condition */

import { put, takeEvery, delay, all } from 'redux-saga/effects'
import {
  INCREMENT_ASYNC,
  increment,
  incrementAsyncSuccess
} from '../actions'

function* helloSaga() {
  yield
  console.log("Welcome to bds.dev \uD83D\uDE00")
}

// worker Saga: will perform the async increment task
export function* incrementAsync() {
  yield delay(1337)
  yield put(increment())             // dispatch an INCREMENT action
  yield put(incrementAsyncSuccess())
}

// watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchIncrementAsync() {
  yield takeEvery(INCREMENT_ASYNC, incrementAsync)
}

// Single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    helloSaga(),
    watchIncrementAsync()
  ])
}
