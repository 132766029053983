// import { combineReducers } from 'redux'
//
// {
//     domainData1 : {},
//     domainData2 : {},
//     appState1 : {},
//     appState2 : {},
//     ui : {
//         uiState1 : {},
//         uiState2 : {},
//     }
// }
//
import {
  INCREMENT,
  INCREMENT_ASYNC,
  INCREMENT_ASYNC_SUCCESS,
  INCREMENT_IF_ODD,
  DECREMENT
} from '../actions'

const initialState = {
  counter: {
    value: 0,
    isFetching: false
  }
}

function counterReducer(counterState, action) {
  switch (action.type) {
    case INCREMENT:
      return Object.assign({}, counterState, {
        value: counterState.value + 1
      })
    case INCREMENT_ASYNC:
      return Object.assign({}, counterState, {
        isFetching: true
      })
    case INCREMENT_ASYNC_SUCCESS:
      return Object.assign({}, counterState, {
        isFetching: false
      })
    case INCREMENT_IF_ODD:
      return Object.assign({}, counterState, {
        value: (counterState.value % 2 !== 0) ?  counterState.value + 1 : counterState.value
      })
    case DECREMENT:
      return Object.assign({}, counterState, {
        value: counterState.value - 1
      })
    default:
      return counterState
  }
}

export default function appReducer(state = initialState, action) {
  return {
    counter: counterReducer(state.counter, action)
  }
}
