// actions
export const INCREMENT = 'INCREMENT'
export const INCREMENT_IF_ODD = 'INCREMENT_IF_ODD'
export const INCREMENT_ASYNC = 'INCREMENT_ASYNC'
export const INCREMENT_ASYNC_SUCCESS = 'INCREMENT_ASYNC_SUCCESS'
export const DECREMENT = 'DECREMENT'
export const SEND_WEBSOCKET_MESSAGE = 'SEND_WEBSOCKET_MESSAGE'

// actionCreators
export const increment = () => ({
  type: INCREMENT
})

export const incrementIfOdd = () => ({
  type: INCREMENT_IF_ODD
})

export const incrementAsync = () => ({
  type: INCREMENT_ASYNC
})

export const incrementAsyncSuccess = () => ({
  type: INCREMENT_ASYNC_SUCCESS
})

export const decrement = () => ({
  type: DECREMENT
})

export const sendSocketMessage = (message) => ({
  type: SEND_WEBSOCKET_MESSAGE,
  payload: message
})
