import {
  createStore,
  applyMiddleware,
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {
  composeWithDevTools
} from 'redux-devtools-extension/logOnlyInProduction';

// reducers
import reducer from '../reducers/index'

// sagas
import rootSaga from '../sagas/index'

// initial states
// initializers

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga)

export default store
